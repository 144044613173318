import { useState } from "react";
import { postInvoicePdf } from "../services";
import { useTranslation } from "react-i18next";
import { TFilterTypeInvoice } from "../interfaces";

export const useDownloadPdfInvoice = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false); // Nuevo estado para controlar la visibilidad de la alerta

  const fetchDownloadPdf = async (numberOrder:string, type: TFilterTypeInvoice) => {
    setLoading(true);

    const { isLoader, isError } = await postInvoicePdf(numberOrder, type);

    if (isError) {
      setMessage(t("global.customerV2.invoice.download.alert.unsuccessful"));
      setHasError(isError);
      setShowAlert(true); // Mostrar la alerta en caso de error
    } else {
      setMessage(t("global.customerV2.invoice.download.alert.successful"));
      setHasError(isError);
      setShowAlert(true); // Mostrar la alerta en caso de éxito también
    }

    setLoading(isLoader);

    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  return {
    loading,
    message,
    hasError,
    showAlert, // Retornar el estado para controlar la visibilidad de la alerta
    fetchDownloadPdf,
  };
};
