import { Fragment } from "react";
import { HelpCenterHeader } from "./HelpCenterHeader";
import Header from "../../../components/header/Header";
import { FooterWave } from "../../../components/footer/FooterWave";
import { NavbarHelpCenter } from "./NavbarHelpCenter";
import { getToken, isValidToken } from "../../../utils";
import { IChildren } from "../../../interfaces";

export const Layout = ({ children }: IChildren) => {
  const token = isValidToken(getToken());

  return (
    <Fragment>
      <HelpCenterHeader>
        {token ? <Header /> : <NavbarHelpCenter />}
      </HelpCenterHeader>
      {children}
      {token && <FooterWave />}
    </Fragment>
  );
};
