import { API_URLS } from "../../constants";
import { makeApiCall } from "../apiCall";

const updateUserEmail = async (oldEmail: string, newEmail: string) => {
  const body = {
    oldEmail,
    newEmail,
  };

  try {
    const { URL_UPDATE_EMAIL_USER_ADMIN } = API_URLS;
    const { data } = await makeApiCall<any>(
      "PATCH",
      URL_UPDATE_EMAIL_USER_ADMIN,
      body
    );

    return { message: data, isError: false };
  } catch (error) {
    const errorMsg =
      typeof error.response.data === "string"
        ? error.response.data
        : error.response?.data.error;

    return {
      isError: true,
      message: errorMsg || "There was a problem with the server",
    };
  }
};

export { updateUserEmail };
