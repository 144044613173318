import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCustomerStore } from "../store/customerStore";
import { useDataLayer } from "./";
import { getToken } from "../utils";
import { TIMER_REFERRALS_POPUP } from "../constants";

export default function useReferralsPopup() {
  const isAdmin = localStorage.getItem("isAdmin");
  const { shouldDisplayReferralModal, setShowReferralModal, language } =
    useCustomerStore((store) => store);
  const { setDataLayer } = useDataLayer();
  const token = getToken();
  //check route is not referralBoard
  const location = useLocation();

  useEffect(
    function displayReferralsPopup() {
      const timeOut = setTimeout(() => {
        if (!shouldDisplayReferralModal) return;
        if (isAdmin || !token) return;
        if (location.pathname === "/referralBoard") return;

        setShowReferralModal(true);
        setDataLayer({
          event: "display_popup",
          language,
        });
      }, TIMER_REFERRALS_POPUP);

      return () => clearTimeout(timeOut);
    },
    [
      isAdmin,
      token,
      setShowReferralModal,
      location,
      language,
      setDataLayer,
      shouldDisplayReferralModal,
    ]
  );
}
