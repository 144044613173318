export enum RoutesNameAdmin {
  "dashboard" = "/dashboard",
  "changeEmail" = "/changeEmail",
}

export interface RouteConfig {
  index?: boolean;
  path: string;
  element: JSX.Element;
  layout?: boolean;
  subRoutes?: RouteConfig[];
}
