import { useRef } from "react";
import { mdiContentCopy, mdiDotsVertical, mdiTrayArrowDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../../hooks";
import HButton from "../../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../../store/customerStore";
import { IDropdownInvoiceCard } from "../../../interfaces";

export function DropdownInvoiceCard({
  orderId,
  downloadPdf,
  copyToClipboard,
  isDownloadInProgress,
}: IDropdownInvoiceCard) {
  const { invoiceCardId, setInvoiceCardId } = useCustomerStore();
  const { t } = useTranslation();

  const dropdownRef = useRef<HTMLDivElement>(null);

  function toggleMenu() {
    if (invoiceCardId === orderId) {
      return setInvoiceCardId(null);
    }
    setInvoiceCardId(orderId);
  }

  function onCopyToClipboard() {
    copyToClipboard();
    setInvoiceCardId(null);
  }

  function onDownloadPdf() {
    if (!isDownloadInProgress) downloadPdf();
    setInvoiceCardId(null);
  }

  useOutsideClick(dropdownRef, () => setInvoiceCardId(null));

  return (
    <div className="relative">
      <HButton
        name="button options"
        type="button"
        onClick={toggleMenu}
        containerStyles="flex items-center"
        className=""
        icon={mdiDotsVertical}
      />

      {invoiceCardId !== orderId ? null : (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          ref={dropdownRef}
        >
          <HButton
            name="button copy order"
            type="button"
            className="flex gap-x-1 w-full justify-start text-left px-5 py-3 text-sm text-neutral-charcoal hover:bg-neutral-100 align-middle items-center font-medium"
            onClick={onCopyToClipboard}
            text={t("global.receiptDetails.button.copyOrder")}
            icon={
              <Icon
                path={mdiContentCopy}
                size={0.8}
                color="black"
                className="text-black"
              />
            }
          />

          <HButton
            name="button download receipt"
            type="button"
            className={`flex gap-x-1 w-full text-left px-5 py-3 text-sm   items-center font-medium ${
              isDownloadInProgress
                ? "text-neutral-400"
                : "text-neutral-charcoal hover:bg-neutral-100"
            }`}
            onClick={onDownloadPdf}
            text={t("global.customerV2.invoice.details.textButtonReceipt")}
            disabled={isDownloadInProgress}
            icon={
              <Icon
                path={mdiTrayArrowDown}
                size={0.8}
                color={isDownloadInProgress ? "#CCD0D7" : "black"}
                className="text-black"
              />
            }
          />
        </div>
      )}
    </div>
  );
}