import { Trans, useTranslation } from "react-i18next";
import { LinkText } from "../../../components/linkText/LinkText";
import { TDescItemElement } from "../../../interfaces";

export const DescItemElement = ({ item }: TDescItemElement): JSX.Element => {
  const { t } = useTranslation();
  const { response, link } = item;

  if (Array.isArray(response)) {
    return (
      <div className="flex flex-col">
        {response.map((paragraph, index) => (
          <p className="mb-4 last:mb-0 text-neutral-charcoal" key={index}>
            {t(paragraph)}
          </p>
        ))}
      </div>
    );
  } else if (link) {
    return (
      <span className="text-neutral-charcoal">
        <Trans
          i18nKey={response}
          components={{
            a: (
              <LinkText
                to={t(link)}
                className="font-bold underline cursor-pointer"
              />
            ),
            email: (
              <LinkText
                to={"mailto:help@holafly.com"}
                className="font-bold underline cursor-pointer"
              />
            ),
          }}
        />
      </span>
    );
  } else {
    return <p>{t(response)}</p>;
  }
};