import { t } from "i18next";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore } from "../../../interfaces";

export const ItemNavInstructions = (): JSX.Element => {
  const { isModifiedDataAccount } = useCustomerStore((store: ICustomerStore) => store);

  return (
    <>
      {isModifiedDataAccount ? (
        <span className="inline cursor-pointer hover:text-red-500 font-medium">
          {t("global.customerV2.header.nav.item2")}
        </span>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={t(
            "global.login.helpCenter.sectionInstructions.urlInstructions"
          )}
          className="inline cursor-pointer hover:text-red-500 font-medium">
          {t("global.customerV2.header.nav.item2")}
        </a>
      )}
    </>
  );
};
