import { SkeletonItems } from "./ItemsInvoiceSkeleton";

export const InvoiceSkeleton = () => {
  return (
    <article
      data-testid="invoice-skeleton"
      className="w-full flex flex-col animate animate-pulse gap-[40px] lg:gap-[24px]"
    >
      <div className="bg-[#E4E6EA] w-full lg:w-[322.67px] h-[40px] lg:h-[50px] rounded-[12px]"></div>
      <SkeletonItems />

      <div className="flex flex-col lg:flex-row gap-[30px] lg:gap-0 w-full items-center lg:items-start lg:justify-between">
        <div className="h-[72px] lg:h-[50px] w-[185px] lg:w-[204px] rounded-[12px] bg-[#E4E6EA]"></div>
        <div className="h-[72px] lg:h-[50px] w-full lg:w-[204px] rounded-[12px] bg-[#E4E6EA]"></div>
      </div>
    </article>
  );
};