import React from "react";
import { useTranslation } from "react-i18next";
import { UnlimitedComsuptionDetails } from "./UnlimitedComsuptionDetails";
import { ChartCircle } from "../../../../components/bandWidth/ChartCircle";

import { formatMBytes } from "../../../../services/math";

import { ITypePlanProps } from "../../../../interfaces";

export const TypePlan = ({
  isLimited: isUnlimited,
  percentNameBytes,
  totalDataMbNameBytes,
  usedDataPercent,
  remainingDays,
  daysTotal,
  usedData,
}: ITypePlanProps): JSX.Element => {
  const currentUseData = formatMBytes(usedData).split("GB")[0];
  const { t } = useTranslation();

  if (isUnlimited === null || isUnlimited === undefined) return <></>;

  return (
    <>
      {!isUnlimited ? (
        <ChartCircle
          size={110}
          percent={Number(usedDataPercent)}
          dataConsumed={percentNameBytes}
          dataRestant={totalDataMbNameBytes}
          trackWidth={1}
          indicatorWidth={5}
          customContent={
            <div className="flex flex-col justify-center">
              <p className="text-[10px] font-normal text-[#292B2E] max-w-[90px]">
                {t("global.customerV2.details.active.textComsuption")}
              </p>
              <p className="text-[22px] font-medium">
                {Number(currentUseData).toFixed(2)}
              </p>
              <p className="text-[10px] font-normal text-[#292B2E]">
                {t("global.customerV2.details.active.textComsuptionGbs", {
                  gb: totalDataMbNameBytes,
                })}
              </p>
            </div>
          }
        />
      ) : (
        <UnlimitedComsuptionDetails
          daysRestant={daysTotal - remainingDays}
          daysTotal={daysTotal}
        />
      )}
    </>
  );
};
