import { useState, useEffect } from "react";
import { getUserOrders } from "../services";
import { InvoiceFilterType, IPropsOrders, IUseUserOrders } from "../interfaces";

export const useUserOrders = (
  customerId: string,
  initialPage: number = 1,
  initialPerPage: number = 9
): IUseUserOrders => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IPropsOrders>({
    count: 0,
    pages_count: 0,
    orders: [],
    totalInvoice: 0,
  });
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [filter, setFilter] = useState<InvoiceFilterType>(
    InvoiceFilterType.ALL
  );

  const abortController = new AbortController();
  const signal = abortController.signal;

  const fetchUserOrders = async () => {
    setLoading(true);

    const { data, isError } = await getUserOrders(
      {
        customerId,
        page,
        perPage: initialPerPage,
        filter,
      },
      signal
    );

    if (isError) {
      setLoading(false);
      setIsError(isError);
      return;
    }

    setLoading(false);
    setOrders((prev) => ({
      pages_count: data.pages_count,
      orders: [...prev.orders, ...data.orders],
      count: data.count,
      totalInvoice: filter === "all" ? data.count : prev.totalInvoice,
    }));
    setIsError(isError);
  };

  useEffect(() => {
    fetchUserOrders();

    return () => {
      abortController.abort();
    };
  }, [customerId, page, filter]);

  const updatePage = () => {
    setPage((prev) => prev + 1);
  };

  const refreshOrders = () => {
    fetchUserOrders();
  };

  const changeInvoiceFilter = (newFilter: InvoiceFilterType) => {
    setFilter(newFilter);
    setLoading(true);
    setPage(1);
    setOrders((prev) => ({
      count: 0,
      pages_count: 0,
      orders: [],
      totalInvoice: prev.totalInvoice,
    }));
  };

  return {
    loading,
    orders,
    isError,
    page,
    filter,
    updatePage,
    refreshOrders,
    changeInvoiceFilter,
  };
};