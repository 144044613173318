declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export type Platform = "Windows" | "Mac";

export type StateOrders = "FINISHED" | "PENDING" | "ACTIVE" | "ACTIVATION_QR";

export type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface IChildren {
  children: JSX.Element | JSX.Element[];
}

export interface IPropsHtml {
  testId: string
}

export interface ICurrency {
  label: string,
  navbarLabel: string,
  id: string,
  geolocation: string
  currency: string
}

export enum InvoiceFilterType {
  ALL = "all",
  ESIM = "esim",
  TOPUPS = "topups",
}

export type TFilterTypeInvoice = "esim" | "topups";