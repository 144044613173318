import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import { mdiCheck } from "@mdi/js";
import HButton from "../../../components/common/hbutton/HButton";
import { DropdownInvoiceCard } from "./DropdownInvoiceCard";
import { useMonthShortName } from "../../../utils";
import { useCustomerStore } from "../../../store/customerStore";
import { IPreviewInvoiceProps } from "./previewInvoiceInterface";

export const PreviewInvoice = ({
  date,
  order_name,
  price,
  flagUrl,
  bundle,
  destination,
  type,
  isDisabled,
  handleDownLoadPdf,
}: IPreviewInvoiceProps): JSX.Element => {
  const reversedDate = date.split("-");
  const shortMonthName = useMonthShortName(Number(reversedDate[1]));
  const invoiceDate = [reversedDate[2], shortMonthName, reversedDate[0]].join(
    " "
  );

  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const { setInvoiceType } = useCustomerStore((store) => store);
  const [displayToast, setDisplayToast] = useState(false);
  const navigate = useNavigate();

  const typeTranslationKey =
    type === "topups"
      ? "global.purchaseReceipts.pill.top-up"
      : "global.purchaseReceipts.pill.esim";

  const handleNavigate = () => {
    setInvoiceType(type);
    navigate(`/invoice/${order_name.split("#")[1] || order_name}`);
  };

  const copyToClipboard = () => {
    setDisplayToast(true);
    navigator.clipboard.writeText(order_name);

    setTimeout(() => {
      setDisplayToast(false);
    }, 2000);
  };

  return (
    <article
      data-testid="preview-invoice"
      className="relative hover:shadow-card flex flex-col w-full lg:w-[322.67px] h-auto rounded-xl border border-[#E4E6EA] bg-[#fff]"
    >
      {displayToast && (
        <span className="flex bg-black text-white px-6 py-3 rounded-[48px] absolute -top-11 left-1 w-fit items-center animate-fadeIn  text-sm gap-x-1 shadow-lg">
          <Icon path={mdiCheck} size={0.8} />
          {t("global.details.manualInstallationModule.copy")}
        </span>
      )}
      <div className="flex w-full bg-neutral-200 px-4 py-2 rounded-t-[12px] text-neutral-charcoal justify-between items-center">
        <div className="flex gap-x-1">
          <HButton
            onClick={copyToClipboard}
            className=""
            name="button copy invoice id"
            type="button"
            text={t(
              "global.customerV2.invoice.details.orderDetailBanner.title",
              {
                order: order_name,
              }
            )}
          ></HButton>
        </div>

        <DropdownInvoiceCard
          orderId={order_name}
          copyToClipboard={copyToClipboard}
          isDownloadInProgress={isDisabled}
          downloadPdf={() => handleDownLoadPdf(order_name)}
        />
      </div>
      <div className="p-5 flex flex-col gap-5">
        <span className="bg-blue-100 px-3 py-1 rounded-3xl text-semantic-info-600 w-fit">
          {t(typeTranslationKey)}
        </span>
        <div className="flex items-start">
          <img
            src={flagUrl}
            alt="bundle flag"
            width={24}
            height={24}
            className="w-6 h-6 mr-1"
          />
          <div>
            <p className="text-base leading-[22px]">{destination[lang]}</p>
            <p className="text-texts-secondary">{bundle[lang]}</p>
          </div>
        </div>

        <hr />

        <div className="flex w-full justify-between">
          <p className="text-[14px] font-normal leading-5">Date</p>
          <p className="text-[16px] font-bold leading-[22px]">{invoiceDate}</p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-[14px] font-normal leading-5 ">
            {t("global.invoice.total.label")}
          </p>
          <p role="paragraph" className="text-[16px] font-bold leading-[22px] ">
            ${price?.amount || ""} {price?.currency_code || ""}
          </p>
        </div>
        <HButton
          name="button receipt details"
          type="button"
          onClick={handleNavigate}
          containerStyles="bg-white hover:bg-neutral-200 border border-neutral-300 rounded-xl h-12"
          className="py-3 px-5 text-base font-medium text-neutral-charcoal"
          text={t("global.customerV2.invoice.details.title")}
        />
      </div>
    </article>
  );
};