import { FC } from "react";
import Layout from "./Layout";

interface IRouteLayout {
  isLayout: boolean;
  element: JSX.Element;
}

export const RouteLayout: FC<IRouteLayout> = ({ isLayout, element }) => {
  return isLayout ? <Layout>{element}</Layout> : element;
};