import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiLoading,
} from "@mdi/js";
import { Alert } from "./Alert";
import { IAlertNotification } from "../../interfaces";
import { Fragment, useEffect } from "react";

export const AlertNotification = ({
  alertType,
  message,
  customClassName,
  isVisible,
  resetAlert,
}: IAlertNotification): JSX.Element => {
  const alertStyles = {
    success: {
      icon: mdiCheckCircleOutline,
      textColor: "text-semantic-success-600",
      bgColor: "bg-semantic-success-100",
      animate: "",
    },
    error: {
      icon: mdiAlertCircleOutline,
      textColor: "text-semantic-error-600",
      bgColor: "bg-semantic-error-100",
      animate: "",
    },
    loader: {
      icon: mdiLoading,
      textColor: "text-semantic-info-600",
      bgColor: "bg-semantic-info-100",
      animate: "animate-spin",
    },
  };

  const { icon, textColor, bgColor, animate } = alertStyles[alertType];

  useEffect(() => {
    if (resetAlert) resetAlert();
  }, [message]);

  if (!isVisible) return <Fragment />;

  return (
    <Alert type="custom" customStyle={`${customClassName} ${bgColor}`}>
      <div className={`flex flex-row gap-4 items-center w-full h-5`}>
        <Icon path={icon} size={0.8} className={`${textColor} ${animate}`} />
        <p
          data-testid="alert-notification-text"
          className={`font-normal text-sm leading-5 ${textColor}`}
        >
          {message}
        </p>
      </div>
    </Alert>
  );
};