import axios from "axios";
import { getToken } from "../utils";

export const useAxios = () => {
  const publicUrls = ["/user/signIn", "/user/signUp", "/graphql"];

  const token = getToken() ?? "";

  let headers: any = {
    "Content-Type": "application/json",
  };

  type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

  const apiCall = (method: Methods, route: string, body?: any) => {
    if (!publicUrls.some((element) => route.includes(element))) {
      headers = { ...headers, token };
    }

    const iAxios = axios.create({
      headers,
      transformRequest: [
        (data) => {
          return JSON.stringify(data);
        },
      ],
    });

    return iAxios({
      method,
      url: route,
      data: body,
    });
  };

  return { apiCall };
};