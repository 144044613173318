import { IEventStore } from "../interfaces/store/eventStoreInterface";

export const createEventSlice = (set): IEventStore => ({
  isOpenMenuMobile: false,
  toggleMenuMobile: (isOpenMenuMobile) => set({ isOpenMenuMobile }),
  isOpenMenuLanguage: false,
  toggleMenuLanguage: (isOpenMenuLanguage) => set({ isOpenMenuLanguage }),
  isAlertCHM: JSON.parse(localStorage.getItem("isAlertCHM") || "true"),
  setIsAlertCHM: (isAlertCHM) => {
    set({ isAlertCHM });
    localStorage.setItem("isAlertCHM", JSON.stringify(isAlertCHM));
  },
  hasSeenOnboardingTravelClub: JSON.parse(
    localStorage.getItem("hasSeenOnboardingTravelClub") || "false"
  ),
  setHasSeenOnboardingTravelClub: (hasSeenOnboardingTravelClub) => {
    set({ hasSeenOnboardingTravelClub });
    localStorage.setItem(
      "hasSeenOnboardingTravelClub",
      JSON.stringify(hasSeenOnboardingTravelClub)
    );
  },
  hasSeenInvoiceRequest: JSON.parse(
    localStorage.getItem("hasSeenInvoiceRequest") || "false"
  ),
  setHasSeenInvoiceRequest: (hasSeenInvoiceRequest: boolean) => {
    set({ hasSeenInvoiceRequest });
    localStorage.setItem(
      "hasSeenInvoiceRequest",
      JSON.stringify(hasSeenInvoiceRequest)
    );
  },
  isAlertRequestInvoice: Boolean(
    JSON.parse(localStorage.getItem("isAlertRequestInvoice") || "false")
  ),
  setIsAlertRequestInvoice: (isAlertRequestInvoice) => {
    set({ isAlertRequestInvoice });
    localStorage.setItem(
      "isAlertRequestInvoice",
      JSON.stringify(isAlertRequestInvoice)
    );
  },
/**
 * Estado global de notificación de alerta.
 */
  globalAlertNotification: { message: "", isError: false },
  setGlobalAlertNotification: (globalAlertNotification) => set({ globalAlertNotification }),
  invoiceCardId: null,
  setInvoiceCardId: (invoiceCardId) => set({ invoiceCardId }),
});
