import { useTranslation } from "react-i18next";
import { ArrowEast } from "../../svg";
import { Divider } from "../../common/divider/Divider";
import { useCustomNavigate, useDataLayer } from "../../../hooks";
import { IItemsMobileProps, ICustomerStore } from "../../../interfaces";
import { useCustomerStore } from "../../../store/customerStore";

export const ItemsMobile = ({
  title,
  path,
  offDivider,
  isNewItem,
}: IItemsMobileProps): JSX.Element => {
  const { t } = useTranslation();
  const { setDataLayer } = useDataLayer()
  const { toggleMenuMobile } = useCustomerStore((store: ICustomerStore) => store);
  const navigate = useCustomNavigate();

  const defaultClick = () => {
    setDataLayer({ aff_htc: null }); 
    setDataLayer({
      event: "aff_htc_menu_options",
      aff_htc: {
        aff_htc_menu_options_clicked: t(title, { lng: "en" }),
      },
    });
    toggleMenuMobile(false);
    navigate(path);
  };

  return (
    <div
      data-testid="items-mobile"
      className="relative w-full h-[56px] flex flex-col items-start justify-center py-4"
    >
      <div
        data-testid="redirect_navigation_page"
        className="flex h-full w-full justify-between items-center"
        onClick={defaultClick}
      >
        <div className="flex gap-2 h-full justify-start items-center ">
          <p className="text-[15px] font-medium">{t(title)}</p>
          {isNewItem && (
            <div
              data-testid="chip"
              style={{
                background: "var(--semantic-info-100, #EBF3FF)",
              }}
              className="flex justify-center items-center  px-[8px] rounded-[80px]"
            >
              <p
                style={{
                  color: "var(--semantic-info-500, #2065CE)",
                }}
                className="text-[10px] font-bold"
              >
                {t("global.customerV2.header.chip")}
              </p>
            </div>
          )}
        </div>
        <ArrowEast />
      </div>
      {!offDivider && <Divider className="w-full absolute bottom-0" />}
    </div>
  );
};
