import { Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TabsControl } from "../../../components/tabsBar/TabsControl";
import { AlertNotification } from "./AlertNotification";
import { InstallationCodeGuideCard } from "./InstallationCodeGuideCard";
import { QrCodeGuideCard } from "./QrCodeGuideCard";
import { VideoSection } from "./VideoSection";
import { IGuideCardProps } from "../../../interfaces";

export const GuideCard = ({ data }: IGuideCardProps): JSX.Element => {
  const { state } = useLocation();
  const guideRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (state?.section && guideRefs.current[state.section]) {
      guideRefs.current[state.section]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [state]);

  return (
    <Fragment>
      {data.map((info, index) => {
        const sectionId = `section-${info.step}`; // Asigna un id único

        return (
          <article
            id={sectionId}
            ref={(el) => (guideRefs.current[sectionId] = el as HTMLDivElement)}
            data-testid="guide-card"
            key={index}
            className={`mt-6 ${
              index === 0 ? "mb-7 md:mb-10" : "mb-0"
            }  relative p-[30px] md:p-[50px]  mx-auto w-full flex flex-col  
            lg:flex-row rounded-lg shadow-card items-center md:justify-between md:gap-5 2xl:w-[60%]`}
          >
            <div className="flex flex-col w-full md:max-w-[430px]">
              <div className="flex w-full items-center gap-3">
                <div className="flex items-center justify-center bg-black text-white w-[32px] h-[32px] rounded-full font-bold">
                  {info.step}
                </div>
                <h1 className="font-medium text-[22px]">{info.title}</h1>
              </div>
              <AlertNotification message={info.alertMessage} />
              {info.tabControl && (
                <TabsControl
                  tabsData={info.tabControl.tabsData}
                  activeTab={info.tabControl.activeTab}
                  handleTabClick={info.tabControl.handleTabClick}
                  widthContainer="md:w-full"
                  widthTab="w-full"
                />
              )}
              {info.tabControl ? (
                <h2 className="mt-4 font-bold text-base">
                  {info.instructions[info.tabControl.activeTab].subtitle}
                </h2>
              ) : (
                <h2 className="mt-4 font-bold text-base">
                  {info.instructions[0].subtitle}
                </h2>
              )}
              <ul className="mt-4 list-disc pl-6 font-[400] text-[14px]">
                {info.tabControl
                  ? info.instructions[info.tabControl.activeTab].list.map(
                      (element, index) => (
                        <li className="mt-2" key={index}>
                          {element}
                        </li>
                      )
                    )
                  : info.instructions[0].list.map((element, index) => (
                      <li className="mt-2" key={index}>
                        {element}
                      </li>
                    ))}
              </ul>

              {state?.prevRoute === "details" &&
                info.step === 1 &&
                info.tabControl &&
                (info.tabControl.activeTab === 0 ? (
                  <InstallationCodeGuideCard
                    customerId={state.data.customer_id}
                    iccid={state.data.iccid}
                  />
                ) : (
                  <QrCodeGuideCard
                    customerId={state.data.customer_id}
                    cardId={state.id}
                  />
                ))}
            </div>
            <VideoSection
              instructions={info.instructions}
              activeTab={info.tabControl?.activeTab || 0}
            />
          </article>
        );
      })}
    </Fragment>
  );
};
