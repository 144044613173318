import { useTranslation } from "react-i18next";

export interface Isuggestion {
  title: string;
  icon: JSX.Element;
}
export const ActiveEsimSuggestion = ({
  suggestions,
}: {
  suggestions: Isuggestion[];
}) => {
  const {t} = useTranslation();

  return (
    <div className="flex flex-col items-center my-[20px]">
      <div className="w-full flex flex-col items-center lg:flex-row lg:justify-center lg:flex-wrap gap-5 lg:gap-[48px]">
        {suggestions.map((item, idx) => (
          <article
            data-testid="esim-suggest-title"
            key={idx}
            className="flex gap-[8px]  lg:flex-col lg:justify-between items-center lg:w-[111px]"
          >
            {item.icon}
            <p className="text-center font-bold text-[16px]">{t(item.title)}</p>
          </article>
        ))}
      </div>
    </div>
  );
};
