import { useEffect } from "react";
import {useLocation } from "react-router-dom";
import { useDataLayer, useScreen } from "../hooks";
import AccountPage from "../pages/account/AccountPage";
import HistoryTravelClubPage from "../pages/referralBoard/HistoryTravelClubPage";
import DetailsPage from "../pages/details/Details";
import DetailsInvoicePage from "../pages/invoice/DetailsInvoicePage";
import InvoicePage from "../pages/invoice/InvoicePage";
import MainPage from "../pages/main/Main";
import { AboutTravelClubPage } from "../pages/referralBoard/AboutTravelClubPage";
import { OnboardingTravelClubModal } from "../pages/referralBoard/components/modals/OnboardingTravelClubModal";
import { ReferralBoard } from "../pages/referralBoard/ReferralBoard";
import { DeleteAccountPage } from "../pages/deleteAccount/DeleteAccountPage";
import { StepInstructions } from "../pages/details/components/stepInstructions/StepInstructions";
import { InvoiceRequestModal } from "../pages/invoice/components/modals/InvoiceRequestModal";

import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore, RouteConfig } from "../interfaces";

export const RoutesPrivate = () => {
  const { customerId: customerIdZustand } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const customerId = localStorage.getItem("customerId") || customerIdZustand;
  const location = useLocation();
  const { setDataLayer } = useDataLayer();

  useEffect(() => {
    if (setDataLayer && customerId) {
      setDataLayer({ userId: customerId });
    }
  }, [location.pathname, setDataLayer, customerId]);

  const { hasSeenOnboardingTravelClub, hasSeenInvoiceRequest } =
    useCustomerStore((store: ICustomerStore) => store);

  const screenIsSmall = useScreen() < 1024;

  const renderReferralBoard =
    screenIsSmall && !hasSeenOnboardingTravelClub ? (
      <OnboardingTravelClubModal closeModal={() => {}} />
    ) : (
      <ReferralBoard />
    );

  const renderDetailsInvoicePage =
    screenIsSmall && hasSeenInvoiceRequest ? (
      <InvoiceRequestModal refreshOrder={() => {}} closeModal={() => {}} />
    ) : (
      <DetailsInvoicePage />
    );

  const routes: RouteConfig[] = [
    { index: true, path: "/", element: <MainPage /> },
    {
      path: "/details/:iccid",
      element: <DetailsPage />,
      layout: true,
    },
    { path: "/invoice", element: <InvoicePage />, layout: true },
    {
      path: "/invoice/:orderNumber",
      element: renderDetailsInvoicePage,
      layout: true,
    },
    {
      path: "/details/step-instructions/:isCall/:iccid",
      element: <StepInstructions />,
      layout: true,
    },
    { path: "/referralBoard", element: renderReferralBoard, layout: true },
    {
      path: "/referralBoard/history",
      element: <HistoryTravelClubPage />,
      layout: true,
    },
    {
      path: "/referralBoard/about-travel-club",
      element: <AboutTravelClubPage />,
      layout: true,
    },
    { path: "/account", element: <AccountPage /> },
    { path: "/account/delete", element: <DeleteAccountPage />, layout: true },
  ];

  return routes;
};
