import { LoginAdmin } from "../pages/loginAdmin/LoginAdmin";
import { PrivateRouteAdmin } from "../router/PrivateRouteAdmin";
import { HolaflyAdminRoutes } from "./HolaflyAdminRoutes";
import { Login } from "../pages/login/Login";

export const RoutesPublic = [
  { path: "/admin", element: <LoginAdmin /> },
  {
    path: "/admin/*",
    element: (
      <PrivateRouteAdmin>
        <HolaflyAdminRoutes />
      </PrivateRouteAdmin>
    ),
  },
  { path: "/login", element: <Login /> },
];