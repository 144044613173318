import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_V2_PROVIDER } from "../querys/queryDetails";
import { ICustomerStore, TopUp } from "../interfaces";
import { useGetQueuedBundles } from "./";
import { useCustomerStore } from "../store/customerStore";
import { getCurrencyData } from "../utils/getCurrencyData";
import { API_URLS } from "../constants";

interface UseTopupAndPackagesProps {
  idDetail: string;
  destination: string;
  checkNextPackages: boolean;
}

interface UseTopupAndPackagesResult {
  topups: TopUp[];
  loadingTopups: boolean;
  haveNextPackages: boolean | null;
}

export const useTopupAndPackages = ({
  idDetail,
  destination,
  checkNextPackages,
}: UseTopupAndPackagesProps): UseTopupAndPackagesResult => {
  const { queuedBundles } = useGetQueuedBundles(idDetail);
  const [topups, setTopups] = useState<TopUp[]>([]);
  const [loadingTopups, setLoadingTopups] = useState<boolean>(false);
  const [haveNextPackages, setHaveNextPackages] = useState<boolean>(false);
  const { currency } = useCustomerStore((state: ICustomerStore) => state);

  const currentCurrency = getCurrencyData(currency).currency;
  const getTopUps = async (destination: string) => {
    const { URL_GET_TOPUP } = API_URLS;

    try {
      const response = await fetch(
        `${URL_GET_TOPUP(destination, currentCurrency)}`
      );
      const { data } = await response.json();

      if (data) {
        setTopups(data);
        setLoadingTopups(false);
        return;
      }

      setTopups([]);
      setLoadingTopups(false);
    } catch (error) {
      setTopups([]);
      setLoadingTopups(false);
    }
  };

  const getBundles = () => {
    setHaveNextPackages(queuedBundles.length > 0);
  };

  useEffect(() => {
    setLoadingTopups(true);
    if (idDetail !== undefined) {
      getTopUps(destination || "");
    }
  }, [destination, currency]);

  useEffect(() => {
    if (idDetail !== undefined)
      if (checkNextPackages) {
        getBundles();
      } else {
        setHaveNextPackages(false);
      }
  }, [idDetail, checkNextPackages]);

  return {
    topups,
    loadingTopups,
    haveNextPackages,
  };
};
