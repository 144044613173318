import { useTranslation } from "react-i18next";
import HButton from "../../../components/common/hbutton/HButton";
import { INVOICE_FILTER_TYPE } from "../../../constants";
import { InvoiceFilterType } from "../../../interfaces";

export function FilterHeader({
  selected,
  onSelect,
}: {
  selected: InvoiceFilterType;
  onSelect: (selected: InvoiceFilterType) => void;
}) {
  const { t } = useTranslation();
  const selectedClass = selected
    ? "bg-[#292B2E] text-[#FFFFFF] border-[##56575A"
    : "bg-white";

  return (
    <div className="flex justify-center lg:justify-end gap-2 mb-10 md:mb-0 md:w-fit">
      {INVOICE_FILTER_TYPE.map(({ type, label }) => (
        <HButton
          name={`button type ${t(label)}`}
          onClick={() => onSelect(type)}
          className={`rounded-[80px] border py-[11px] px-4 min-w-[48px] h-8 flex items-center text-sm ${
            selected === type ? selectedClass : ""
          }`}
          type="button"
          text={t(label)}
        />
      ))}
    </div>
  );
}