import { useTranslation } from "react-i18next";
import { mdiAndroid, mdiApple, mdiArrowRight } from "@mdi/js";

import { TabsControl } from "../../../components/tabsBar/TabsControl";
import { GuideCard } from "./GuideCard";
import { IBoundle, ICustomerStore, TContentGuide } from "../../../interfaces";

import Icon from "@mdi/react";
import { useCustomerStore } from "../../../store/customerStore";

type TGuideInstallation = {
  guideApple: TContentGuide[];
  guideAndroid: TContentGuide[];
  activeTabGlobal: number;
  activeTabGlobalPhone: number;
  setActiveTabGlobal: (index: number) => void;
  setActiveTabGlobalPhone: (index: number) => void;
};

export const GuideInstallation = ({
  guideApple,
  guideAndroid,
  activeTabGlobal,
  activeTabGlobalPhone,
  setActiveTabGlobal,
  setActiveTabGlobalPhone,
}: TGuideInstallation): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useCustomerStore((store: ICustomerStore) => store);

  const widthButtonInfo: IBoundle = {
    en: "w-48",
    it: "w-72",
    pt: "w-72",
    fr: "w-80",
    de: "w-80",
    es: "w-72",
    ja: "w-48",
    ko: "w-72",
    nl: "w-80",
    __typename: "",
  };

  // useEffect(() => {
  //   if (location.state && location.state.id) {
  //     const element = document.getElementById("section_instructions")!;
  //     element.scrollIntoView({ behavior: "smooth", block: "start" });
  //     return
  //   }
  // }, [location]);

  return (
    <section
      data-testid="guide-installation"
      className="2xl:px-0 bg-white py-20 xl:px-56 lg:px-32 md:px-20 px-8"
    >
      <div className="flex flex-col gap-6 justify-center items-center mb-10">
        <h2 className="font-medium text-[28px] text-center">
          {t("global.login.helpCenter.sectionInstructions.title")}
        </h2>
        <h6 className="font-bold text-base text-center">
          {t("global.login.helpCenter.sectionInstructions.subTitle")}
        </h6>

        <TabsControl
          tabsData={[
            {
              id: "1",
              title: t(
                "global.login.helpCenter.sectionInstructions.tabsGlobal.first"
              ),
              icon: mdiApple,
            },
            {
              id: "2",
              title: t(
                "global.login.helpCenter.sectionInstructions.tabsGlobal.second"
              ),
              icon: mdiAndroid,
            },
          ]}
          handleTabClick={setActiveTabGlobal}
          activeTab={activeTabGlobal}
        />
      </div>

      {activeTabGlobal === 1 ? (
        <>
          <TabsControl
            tabsData={[
              { id: "1", title: "Samsung" },
              { id: "2", title: "Google Pixel" },
            ]}
            handleTabClick={setActiveTabGlobalPhone}
            activeTab={activeTabGlobalPhone}
            bgColorContainer="bg-white"
          />
          <GuideCard data={guideAndroid[activeTabGlobalPhone].index} />
        </>
      ) : (
        <GuideCard data={guideApple[0].index} />
      )}

      <a
        className={`border-2 mx-auto ${widthButtonInfo[language]} mt-12 flex flex-row items-center justify-center py-3 px-5 gap-2 border-neutral-300 rounded-xl hover:shadow-card hover:cursor-pointer transition-all`}
        target="_blank"
        rel="noreferrer"
        href={t("global.login.helpCenter.sectionInstructions.urlInstructions")}
      >
        {t("global.login.helpCenter.sectionInstructions.button")}

        <Icon path={mdiArrowRight} size={0.8} />
      </a>
    </section>
  );
};
