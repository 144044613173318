import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useDownloadPdfInvoice,
  useGetOrderByName,
} from "../../hooks";
import { OrderDetailBanner } from "./components/details/OrderDetailBanner";
import { InvoiceDetailCard } from "./components/details/InvoiceDetailCard";
import { PersonalInfoCard } from "./components/details/PersonalInfoCard";
import { SupportInvoice } from "./components/details/SupportInvoice";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { DetailsInvoiceSkeleton } from "./components/skeletons/DetailsInvoiceSkeleton";
import { ReceiptStatusMessage } from "../../components/alert/ReceiptStatusMessage";
import { NotificationInternalError } from "../../components/alert/NotificationInternalError";
import { ErrorBoundary } from "../../components/errors/ErrorBoundaries";
import { AlertNotification } from "../../components/alert/AlertNotification";
import { useCustomerStore } from "../../store/customerStore";
import { ICustomerStore } from "../../interfaces";

const classNameAlertNotification =
  "mt-20 fixed p-5 rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown flex self-center";

const DetailsInvoicePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { setOrderUser, invoiceType } = useCustomerStore((store: ICustomerStore) => store);
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const {
    fetchDownloadPdf,
    loading: loadingPdf,
    hasError,
    message,
    showAlert,
  } = useDownloadPdfInvoice();
  const { loading, order, error, refreshOrder } = useGetOrderByName(
    orderNumber || "",
    invoiceType,
  );

  const invoiceErrorMessage =
    order.id === 0 && !error ? "Error User" : error ? "Error Internal" : "";

  const isOrderInvalidOrError = order.id === 0 || !!error;

  const shouldShowAlertPdf = !loadingPdf && showAlert;

  useEffect(() => {
    !loading && setOrderUser(order);
  }, [order, loading, setOrderUser]);

  return (
    <LayoutPageWithMapBackground
      goBack
      btnLabel={t("global.customerV2.invoice.details.textGoBack")}
      title={t("global.customerV2.invoice.main.title")}
    >
      {loading ? (
        <DetailsInvoiceSkeleton />
      ) : (
        <ErrorBoundary
          fallBackComponent={
            <FallBackComponent
              invoiceErrorMessage={invoiceErrorMessage}
              refreshOrder={refreshOrder}
            />
          }
          error={isOrderInvalidOrError}
        >
          <div
            data-testid="details-invoice"
            className="flex flex-col gap-10 md:gap-6 transition-all duration-300"
          >
            <OrderDetailBanner
              orderNumber={order.orderNumber}
              date={order.date}
              currencyCode={order.currencyCode}
              currencySymbol={order.currencySymbol}
              totalAmount={order.totalPaid}
            />

            <div className="flex flex-col md:flex-row gap-6 w-full justify-center items-center">
              <InvoiceDetailCard order={order} />
              <PersonalInfoCard
                name={order.customerFirstName}
                lastName={order.customerLastName}
                email={order.customerEmail}
                phone={order.customerPhone}
              />
            </div>

            <SupportInvoice
              isLoadingPdf={loadingPdf}
              onClick={() => {
                fetchDownloadPdf(`#${orderNumber}` || "", order.type);
              }}
              refreshOrder={refreshOrder}
              order={{
                isInvoiceExists: order.invoiceExists,
                orderName: order.orderNumber,
                orderEmail: order.customerEmail
              }}
            />

            {shouldShowAlertPdf && (
              <AlertNotification
                isVisible={showAlert}
                alertType={hasError ? "error" : "success"}
                message={message}
                customClassName={`${classNameAlertNotification} ${
                  !hasError
                    ? "bg-semantic-success-100"
                    : "bg-semantic-error-100"
                }`}
              />
            )}
          </div>
        </ErrorBoundary>
      )}
    </LayoutPageWithMapBackground>
  );
};

export default DetailsInvoicePage;

const FallBackComponent = ({ invoiceErrorMessage, refreshOrder }) => {
  return (
    <Fragment>
      {invoiceErrorMessage === "Error User" ? (
        <ReceiptStatusMessage />
      ) : (
        <NotificationInternalError
          title="global.customerV2.invoice.alerts.alertConnectionInternalError.title"
          description="global.customerV2.invoice.alerts.alertConnectionInternalError.description"
          textButton="global.customerV2.invoice.alerts.alertConnectionInternalError.textButton"
          onClick={refreshOrder}
        />
      )}
    </Fragment>
  );
};