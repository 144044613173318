import Cookies from 'js-cookie';
import { jwtDecode, JwtPayload } from "jwt-decode";

/**
 * Stores the authentication token in cookies.
 * @param {string} token - The authentication token to be stored.
 * @returns {void}
 */
export const setToken = (token: string): void => {
  Cookies.set('token', token);
};

/**
 * Retrieves the authentication token from cookies.
 * @returns {string | null} The stored token, or null if not found.
 */
export const getToken = (): string | null => {
  return Cookies.get('token') || null;
};

/**
 * Removes the authentication token from cookies.
 * @returns {void}
 */
export const removeToken = (): void => {
  Cookies.remove('token');
};

/**
 * Checks whether a given token is valid (not expired).
 * @param {string | null} token - The token to validate.
 * @returns {boolean} True if the token is valid, false otherwise.
 */
export const isValidToken = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const isExpired = decoded.exp !== undefined && decoded.exp * 1000 < Date.now();
    return !isExpired;
  } catch (error) {
    return false;
  }
};