export const SkeletonItems = () => {
  const CreateBox = (): JSX.Element => (
    <div
      data-testid="skeleton-box"
      className=" w-full lg:w-[322.67px] h-[224px] rounded-[12px] bg-[#E4E6EA]"
    ></div>
  );

  const renderBoxes = (numberItems: number) =>
    Array.from({ length: numberItems }, (_, i) => (
      <CreateBox key={`box-${i + 1}`} />
    ));

  return (
    <div>
      <div className="hidden w-full lg:flex gap-6 flex-wrap justify-center">
        {renderBoxes(9)}
      </div>
      <div className="flex w-full lg:hidden gap-4 flex-wrap justify-center">
        {renderBoxes(9)}
      </div>
    </div>
  );
}