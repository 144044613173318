import { useTranslation } from "react-i18next";
import { Divider } from "../../common/divider/Divider";
import { ArrowEast } from "../../svg";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore } from "../../../interfaces";
import { useDataLayer } from "../../../hooks";

export const ItemNavInstructionsMobile = (): JSX.Element => {
  const { t } = useTranslation();
  const { isModifiedDataAccount } = useCustomerStore((state: ICustomerStore) => state);
  const {setDataLayer} = useDataLayer()

  const handleSetEvent = () => {
    setDataLayer({ aff_htc: null });
    setDataLayer({
      event: "aff_htc_menu_options",
      aff_htc: {
        aff_htc_menu_options_clicked: t("global.customerV2.header.nav.item2", {
          lng: "en",
        }),
      },
    });
  };

  return (
    <div className="relative w-full h-[56px] flex flex-col items-start justify-center py-[16px]">
      {isModifiedDataAccount ? (
        <div
          data-testid="nav_item2_modified"
          className="flex w-full justify-between"
        >
          <p className="text-[15px] font-medium">
            {t("global.customerV2.header.nav.item2")}
          </p>
          <ArrowEast />
        </div>
      ) : (
        <a
          data-testid="nav_item2_unmodified"
          target="_blank"
          rel="noreferrer"
          onClick={handleSetEvent}
          href={t(
            "global.login.helpCenter.sectionInstructions.urlInstructions"
          )}
          className="flex w-full justify-between"
        >
          <p className="text-[15px] font-medium">
            {t("global.customerV2.header.nav.item2")}
          </p>
          <ArrowEast />
        </a>
      )}

      <Divider className="w-full absolute bottom-0" />
    </div>
  );
};
