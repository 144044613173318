import { useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useSetLanguageFromUrl } from "../hooks";
import { useSetCurrencyFromUrl } from "../hooks/useSetCurrencyFromUrl";

import { RouteLayout } from "../components/layouts/LayoutRoute";
import LandingPage from "../pages/landing/LandingPage";
import HelpCenterPage from "../pages/help/HelpCenterPage";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { RoutesPrivate } from "../routes/RoutesPrivate";
import { RoutesPublic } from "../routes/RoutesPublic";

import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces/store";

export const AppRouter = (): JSX.Element => {
  const intercom = useIntercom();
  let [searchParams] = useSearchParams();
  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const handleSetLanguageFromUrl = useSetLanguageFromUrl();
  const handleSetCurrencyFromUrl = useSetCurrencyFromUrl();

  useEffect(() => {
    handleSetLanguageFromUrl();
    handleSetCurrencyFromUrl();
  }, [searchParams]);

  useEffect(() => {
    // Configura el idioma de Intercom aquí
    return intercom.update({ languageOverride: language });
  }, [intercom, language]);

  return (
    <Routes>
      {/* Rutas Públicas */}
      {RoutesPublic.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={<PublicRoute>{route.element}</PublicRoute>}
          />
        );
      })}
      {/* Rutas Privadas */}
      {RoutesPrivate().map((route, index) => {
        return (
          <Route
            key={index}
            index={route.index}
            path={route.path}
            element={
              <PrivateRoute>
                <RouteLayout isLayout={route.layout!} element={route.element} />
              </PrivateRoute>
            }
          />
        );
      })}
      {/* Rutas Comunes*/}
      <Route path="/helpCenter" element={<HelpCenterPage />} />,
      <Route path="/loyalty-program" element={<LandingPage />} />,
      {/* Rutas No Encontradas */}
      <Route path="*" element={<Navigate to={"/login"} replace />} />
    </Routes>
  );
};
