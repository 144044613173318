import { makeApiCall } from "../apiCall";
import { IGetOrderByName, IGetOrderByNameResult } from "../../interfaces";
import { API_URLS, INITIAL_RESPONSE_GET_BY_ORDER_NAME } from "../../constants";

export const getOrderByName = async (
  { numberOrder, filter }: IGetOrderByName,
  signal?: AbortSignal
): Promise<IGetOrderByNameResult> => {
  const { URL_GET_ORDER_INVOICE } = API_URLS;
  try {
    const { data, status } = await makeApiCall<any>(
      "GET",
      URL_GET_ORDER_INVOICE(`${numberOrder}`, filter),
      null,
      signal
    );

    if (status === 200) {
      return {
        status,
        data,
        error: null,
      };
    }

    return {
      status,
      data: INITIAL_RESPONSE_GET_BY_ORDER_NAME,
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      status: 500,
      data: INITIAL_RESPONSE_GET_BY_ORDER_NAME,
      error,
    };
  }
};