import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Trans } from "react-i18next";
import paperPlane from "../../../../assets/referrals/PaperPlane.svg";
import refundCash from "../../../../assets/referrals/RefundMoney.svg";
import { redirectToWeb } from "../../../../utils";
import { ReferralFriendLite } from "../referralFriend/ReferralFriendLite";
import { ICustomerStore } from "../../../../interfaces";
import { useCustomerStore } from "../../../../store/customerStore";
import { HelpCenterIcon } from "../../../../components/svg";

interface EarnCoinsProps {
  handleOpenModal: () => void;
  ref: React.RefObject<HTMLDivElement>;
}
export const EarnCoins = ({ handleOpenModal , ref }: EarnCoinsProps) => {
  const { fetchReferralLinkUser, emailLocalStorage } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchReferralLinkUser();
  }, [emailLocalStorage]);

  return (
    <div
      ref={ref}
      data-testid="EarnCoins"
      className="w-full lg:max-w-[1016px] flex flex-col items-center gap-[20px] relative"
    >
      <h3 className="text-[28px] font-medium text-[#292B2E] mt-[40px] lg:mt-[50px] text-center">
        {t("global.customerV2.travelClub.earnHolacoins.title")}
      </h3>

      <div className="relative w-full flex flex-col lg:flex-row gap-[20px] md:gap-[24px]">
        <img
          className="hidden lg:block z-0 absolute -top-[20px] -left-[60px]"
          src={paperPlane}
          alt="paper plane"
        />
        <img
          className="hidden lg:block z-0 absolute -bottom-[25px] -right-[70px]"
          src={refundCash}
          alt="refund cash"
        />
        <article className="w-full flex flex-col p-[20px] gap-[20px] border rounded-xl border-[#E4E6EA] bg-[#F8F8FB]">
          <div className="flex items-center w-full justify-between">
            <p className="text-[16px] font-bold">
              {t("global.customerV2.travelClub.earnHolacoins.card.refer.title")}
            </p>

            <HelpCenterIcon
              className="cursor-pointer"
              onClick={() =>
                navigate("/referralBoard/about-travel-club", {
                  state: { refIndex: 3 },
                })
              }
            />
          </div>
          <p className="text-[16px] font-normal">
            <Trans
              i18nKey={
                "global.customerV2.travelClub.earnHolacoins.card.refer.description"
              }
              components={{
                strong: <strong />,
              }}
              values={{
                holacoins: 100,
                amount: 1,
              }}
            />
          </p>
          <ReferralFriendLite handleOpenModal={handleOpenModal} />
        </article>
        <article className="w-full flex flex-col p-[20px] gap-[20px] border rounded-xl border-[#E4E6EA] bg-[#F8F8FB]">
          <div className="flex items-center w-full justify-between">
            <p className="text-[16px] font-bold">
              {t(
                "global.customerV2.travelClub.earnHolacoins.card.cashback.title"
              )}
            </p>
            <HelpCenterIcon
              className="cursor-pointer"
              onClick={() =>
                navigate("/referralBoard/about-travel-club", {
                  state: { refIndex: 2 },
                })
              }
            />
          </div>
          <p className="text-[16px] font-normal">
            <Trans
              i18nKey={
                "global.customerV2.travelClub.earnHolacoins.card.cashback.description"
              }
              components={{
                strong: <strong />,
              }}
            />
          </p>
          <div
            onClick={redirectToWeb}
            className="mt-auto cursor-pointer py-3 px-5 gap-1 flex justify-between items-center min-h-[48px] hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl"
          >
            <p className="w-full text-center text-sm font-medium whitespace-nowrap">
              {t(
                "global.customerV2.travelClub.earnHolacoins.card.cashback.cta"
              )}
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};
