import { useState } from "react";
import { useScreen, useCustomNavigate, useDataLayer } from "../../../hooks";
import { IMenuItems } from "../../../interfaces";
import "../menuItem/menuItems.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const MenuItems = ({
  items,
  overrideStyles,
  customStyles,
}: IMenuItems): JSX.Element => {
  const screenSize = useScreen();
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const { setDataLayer } = useDataLayer();

  const defaultClick = (title: string, path: string, id: number) => {
    setDataLayer({ aff_htc: null });
    setDataLayer({
      event: "aff_htc_menu_options",
      aff_htc: {
        aff_htc_menu_options_clicked: t(title, { lng: "en" }),
      },
    });

    const redirect = navigate(path);

    if (!redirect) {
      return;
    }
  };

  return (
    <ul
      data-testid="menu-list"
      id="items-list"
      className={
        customStyles
          ? `${customStyles}`
          : `${overrideStyles} flex-col sm:flex-row sm:gap-11 ml-10 w-full h-full`
      }
    >
      {items.map((item, idx) => (
        <div className="group" key={`${item.id}_item${item.name}${item.id}`}>
          {item.isCustomElement ? (
            screenSize <= 425 ? (
              item.customElement
            ) : (
              <li onClick={() => defaultClick(item.name, item.path, item.id)}>
                {item.customElement}
              </li>
            )
          ) : (
            <li
              className="inline cursor-pointer hover:text-red-500 w-auto font-medium"
              onClick={() => defaultClick(item.name, item.path, item.id)}
            >
              {t(item.name)}
              {pathname === item.path && (
                <p
                  data-testid="line_red"
                  className="bg-red-400 sm:w-full m-auto sm:h-[2px] rounded-sm relative top-[22px] z-0"
                />
              )}
            </li>
          )}

          {pathname !== item.path && (
            <p
              data-testid="line_red"
              className="group-hover:bg-red-400  sm:w-full m-auto sm:h-[2px] rounded-sm relative top-[22px] z-0"
            />
          )}
        </div>
      ))}
    </ul>
  );
};
