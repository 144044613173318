import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { differenceInDays, format, parse } from "date-fns";
import { enUS } from 'date-fns/locale';

/**
 * Formatea la fecha de un descuento histórico en una cadena de texto.
 * La cadena resultante depende de cuántos días han pasado desde la fecha original.
 * - Si es hoy, se devuelve una cadena localizada para "hoy".
 * - Si es dentro de la última semana, se devuelve una cadena localizada para "1 día" o "X días".
 * - De lo contrario, se devuelve la fecha en formato "dd/mm/yyyy".
 * 
 * @param {Date} inputDate - La fecha de entrada para formatear.
 * @returns {string} La fecha formateada según el tiempo transcurrido.
 */
export const formatDateHistoryDiscount = (inputDate: Date) => {
  const date = new Date(inputDate);
  const timeZone = "Europe/Berlin";
  const dateOriginal = new Date(date.toLocaleString("en-US", { timeZone }));
  const dateFormat = dateOriginal.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const days = differenceInDays(new Date(), dateOriginal);

  let date_format = "";
  if (days === 0) {
    date_format = t(
      "global.customerV2.referrals.discountCode.discountHistory.listTitleTable.dateNow"
    );
  } else if (days >= 1 && days <= 7) {
    date_format =
      days === 1
        ? t(
            "global.customerV2.referrals.discountCode.discountHistory.listTitleTable.dateDay",
            {
              day: days,
            }
          )
        : t(
            "global.customerV2.referrals.discountCode.discountHistory.listTitleTable.dateDays",
            {
              days: days,
            }
          );
  } else {
    date_format = dateFormat;
  }

  return date_format;
};

/**
 * Formatea una fecha en formato "YYYY-MM-DD" a "DD/MM/YYYY" para la zona horaria de Europa/Berlín.
 * 
 * @param {string} date - La fecha en formato "YYYY-MM-DD".
 * @returns {string} La fecha formateada en el formato "DD/MM/YYYY".
 */
export const dateFormatMMDDYYYY = (date: string): string => {
  const timeZone = "Europe/Berlin";
  return new Date(date).toLocaleDateString("es-ES", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

/**
 * Obtiene el nombre del mes en formato completo para un año y mes específicos.
 * 
 * @param {number} year - El año de la fecha.
 * @param {number} month - El mes de la fecha (1 para enero, 2 para febrero, etc.).
 * @returns {string} El nombre completo del mes.
 */
export const getMonthName = (year: number, month: number) => {
  return format(new Date(year, month - 1), 'MMMM');
}

/**
 * Convierte una fecha en cadena de texto al formato UTC.
 * 
 * @param {string} dateString - La fecha en formato de cadena.
 * @returns {Date} La fecha convertida a UTC.
 */
export const toUTCDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

/**
 * Formatea una fecha en el formato "d MMM. yyyy" (día Mes. año).
 * 
 * @param {string} dateString - La fecha en formato "YYYY-MM-DD".
 * @returns {string} La fecha formateada en el formato "d MMM. yyyy".
 */
export const dateFormatToDayMonthYear = (dateString: string) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const formatPattern = "d MMM. yyyy";
  return format(date, formatPattern, { locale: enUS });
}

/**
 * Custom hook que obtiene el nombre del mes en formato completo basado en un número del 01 al 12.
 *
 * @param {number} monthNumber - El número del mes (01 para enero, 02 para febrero, etc.).
 * @returns {string} El nombre completo del mes.
 */
export const useMonthShortName = (monthNumber: number): string => {
  const { t } = useTranslation();
  const date = new Date(0, monthNumber - 1);
  return t(
    `global.customerV2.month.${date
      .toLocaleString("en-US", {
        month: "long",
      })
      .toLowerCase()}.short`
  );
};