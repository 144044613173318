import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDownloadPdfInvoice, useUserOrders } from "../../hooks";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { InvoiceSkeleton } from "./components/skeletons/InvoiceSkeleton";
import { SkeletonItems } from "./components/skeletons/ItemsInvoiceSkeleton";
import { ErrorBoundary } from "../../components/errors/ErrorBoundaries";
import { ReceiptStatusMessage } from "../../components/alert/ReceiptStatusMessage";
import { NotificationInternalError } from "../../components/alert/NotificationInternalError";
import { PreviewInvoice } from "./components/PreviewInvoice";
import { AlertNotification } from "../../components/alert/AlertNotification";
import { FilterHeader } from "./components/FilterHeader";
import HButton from "../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../store/customerStore";
import { ICustomerStore } from "../../interfaces";

const classNameAlertNotification =
  "mt-20 fixed p-5 rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown flex self-center";

const InvoicePage = (): JSX.Element => {
  const { customerId } = useCustomerStore((store: ICustomerStore) => store);
  const { t } = useTranslation();

  const {
    orders: { orders, count, totalInvoice },
    loading,
    isError,
    filter,
    updatePage,
    refreshOrders,
    changeInvoiceFilter,
  } = useUserOrders(customerId);
  const {
    loading: loadingPdf,
    fetchDownloadPdf,
    hasError,
    message,
    showAlert,
  } = useDownloadPdfInvoice();

  // result contendrá el objeto con la información del error o será vació si no hay error.
  const invoiceErrorMessage =
    !orders.length && !isError ? "Error User" : "Error Internal";

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.invoice.main.title")}
    >
      {loading && orders.length === 0 ? (
        <InvoiceSkeleton />
      ) : (
        <>
          {totalInvoice > 0 && (
            <div className="md:flex flex-row-reverse items-center justify-between md:mb-6 mb-2">
              <FilterHeader selected={filter} onSelect={changeInvoiceFilter} />
              {count > 0 && (
                <span className="md:w-fit">
                  {t("global.pagination.showXofTotalReceipts", {
                    current: orders.length,
                    total: count,
                  })}
                </span>
              )}
            </div>
          )}

          <ErrorBoundary
            fallBackComponent={
              invoiceErrorMessage === "Error User" ? (
                <ReceiptStatusMessage />
              ) : (
                <NotificationInternalError
                  title="global.customerV2.invoice.alerts.alertConnectionInternalError.title"
                  description="global.customerV2.invoice.alerts.alertConnectionInternalError.description"
                  textButton="global.customerV2.invoice.alerts.alertConnectionInternalError.textButton"
                  onClick={refreshOrders}
                />
              )
            }
            error={!orders.length || isError}
          >
            <div
              data-testid="invoice"
              className="relative w-full flex flex-col"
            >
              <div className="w-full flex gap-4 lg:gap-6 flex-wrap justify-center mb-5">
                {orders.map((invoice) => (
                  <PreviewInvoice
                    isDisabled={loadingPdf}
                    handleDownLoadPdf={() => fetchDownloadPdf(invoice.order_name, invoice.type)}
                    key={invoice.order_name}
                    {...invoice}
                  />
                ))}
              </div>
              {loading && <SkeletonItems />}
              {orders.length < count && (
                <HButton
                  type="button"
                  name="load more invoices"
                  disabled={false}
                  onClick={updatePage}
                  text={t("global.loadMore.cta", {
                    number:
                      count - orders.length > 9 ? 9 : count - orders.length,
                  })}
                  containerStyles="flex justify-center mt-5 md:mt-6"
                  className="w-full lg:w-[322.67px] h-[48px] p-[12px] rounded-[12px] border bg-white border-[#E4E6EA] hover:bg-neutral-200"
                />
              )}
            </div>
          </ErrorBoundary>
        </>
      )}

      <AlertNotification
        isVisible={loadingPdf}
        alertType={"loader"}
        message={t("global.customerV2.invoice.download.inProgress")}
        customClassName={`${classNameAlertNotification} w-[90%] md:w-3/2 lg:w-[808px]`}
      />

      {!loadingPdf && showAlert ? (
        <AlertNotification
          isVisible={showAlert}
          alertType={!hasError ? "success" : "error"}
          message={message}
          customClassName={`w-[90%] md:w-3/2 lg:w-[808px] ${classNameAlertNotification} ${
            !hasError ? "bg-semantic-success-100" : "bg-semantic-error-100"
          }`}
        />
      ) : (
        <></>
      )}
    </LayoutPageWithMapBackground>
  );
};

export default InvoicePage;